import { defineStore } from 'pinia'

export const useSearchStore = defineStore({
  id: 'search',
  state: () => ({
    query: ''
  }),
  actions: {
    setQuery(newQuery: string) {
      this.query = newQuery
    }
  }
})
