declare global {
  interface Window {
    gtag: (command: string, config: string, params?: object) => void;
  }
}
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import Cookies from 'js-cookie'
import { useAuthStore } from '@/store/authentication/AuthStore.ts'
import { useSearchStore } from '@/store/search/SearchStore';

const router = createRouter(<any>{
  history: createWebHistory(),
  routes
})

//Navigation guards
router.beforeEach((to, _, next): void => {
  if (to.meta.requiresAuth) {
    // Check if refresh_token and access_token cookies exist
    const hasRefreshToken = Cookies.get('refresh_token')
    const hasAccessToken = Cookies.get('access_token')

    if (hasRefreshToken && hasAccessToken) {
      // If both refresh_token and access_token cookies exist, the user is authenticated
      next()
    } else {
      // If either refresh_token or access_token cookie is missing, redirect to login page
      next('/login')
    }
  } else {
    // If the route does not require authentication, allow access
    next()
  }
})

router.beforeEach((to, _, next) => {
  const authStore = useAuthStore()

  // Check if the user is navigating to the login route
  if (
    to.path === '/login' ||
    to.path === '/forget-password' ||
    to.path === '/sign-up' ||
    to.path === '/sign-up/invitation'
  ) {
    // If the user is already logged in, log them out first
    authStore.logout()
  }

  next() // Continue navigation
})

router.beforeEach((to, _, next) => {
  if (to.path === '/sign-up' && to.query.invitation_id) {
    next({ name: 'InvitationSignup', query: to.query })
  } else if (to.path === '/sign-up/invitation' && !to.query.invitation_id) {
    next({ name: 'SignUp' })
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', 'G-0YWLL90NKB', {
      page_path: to.fullPath,
    });
  }
});

// Reset the search query after user navigates from real estate page to another page
router.beforeEach((to, from, next) => {
  if(from.name && to.name && from.name !== to.name) {
    const searchStore = useSearchStore()
    searchStore.setQuery('')
  }
  next();
})  

export default router
