<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { useAppStore } from '@/store/locale/LocaleStore.ts'
import i18n from '@/libs/i18n'

// Get the theme
const theme = useTheme()

// Computed property to determine the background class based on the theme
const appBackgroundClass = computed(() => {
  return theme.global.current.value.dark ? 'custom-background-dark' : 'custom-background'
})


const initializeLocale = () => {
  const appStore = useAppStore();
  const savedLanguage = localStorage.getItem('lang') || 'en';
  appStore.setLocale(savedLanguage);
  i18n.global.locale.value = savedLanguage;
};

initializeLocale();
</script>

<template>
  <v-app id="app" :class="appBackgroundClass">
    <TheAppLayout />
  </v-app>
</template>

<style></style>
